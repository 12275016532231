@use "../mixins/mq";
@use "../_config/colors";

.splide__banner {
    width: 100vw;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);

    @include mq.mq($from: large) {
        width: calc(100vw - 15px);
    }
}

.slide-client {

    &__logo {
        height: 25px;
        width: auto;

        @include mq.mq($from: large) {
            height: 55px;
        }
        
        svg {
            height: inherit;
            width: inherit;
        }
    }
    
}