@use "../mixins/mq";

.accordion {
    border-bottom: 1px solid currentColor;
    font-size: 1.6rem;



    &__header {
        margin: 4rem 0 1rem;
    }

    &__item {
        padding-bottom: 1.7rem;

        &[open] {
            --toggle-rotation: 180deg;
        }
    }

    &__title {
        padding: 3rem 5.7rem 1.3rem 0;
        list-style: none;
        cursor: pointer;
        position: relative;
        border-top: 0.1rem solid currentColor;
        font-size: 1.8rem;
        line-height: 1.444444444;
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        user-select: none;

        &::-webkit-details-marker {
            display: none;
        }

    }

    &__toggle {
        width: 2.5rem;
        aspect-ratio: 1;
        position: absolute;
        top: 3rem;
        right: 0.4rem;
        display: block;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 0;
            border: .2rem solid var(--color-secondary-1);
            top: 50%;
            transition: transform var(--animationDuration) var(--animationEase);
            transform: rotate(var(--toggle-rotation, 0deg));
            will-change: transform;
        }

        &:after {
            transform: rotate(var(--toggle-rotation, 90deg));
        }
    }


    //&__body {}

    @include mq.mq($from: large) {
        font-size: inherit;

        &__title {
            font-size: 3.5rem;
            line-height: 1.285714286;
            font-weight: 300;
            letter-spacing: .05rem;
            text-transform: none;
            padding: 5.7rem 5.7rem 2rem 0;

            .accordion--small & {
                font-size: 1.8rem;
                line-height: 2.6rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
                padding: 3rem 3rem 1.9rem 0;
            }
        }



        &__toggle {
            top: 5.8rem;
            width: 4rem;

            .accordion--small & {
                top: 3rem;
                width: 2.5rem;
            }

        }

        &__item {
            padding-bottom: 3.7rem;

            .accordion--small & {
                padding-bottom: 1.1rem;

                &[open] {
                    padding-bottom: 3rem;
                }
            }
        }

    }

}