@use "../mixins/mq";
@use "../_config/colors";
@use "../_base/typography";

.job {
  &__header {
    margin-bottom: 0;
    hyphens: none;

    @include mq.mq($from: large) {
      margin-bottom: 5.2rem;
      font-size: 7rem;
    }
  }

  &__apply {
    margin-bottom: 3rem;

    @include mq.mq($from: large) {
      margin-bottom: 6rem;
    }
  }

  &__description {
    padding-top: 4rem;
    padding-bottom: 6rem;

    > div {
      margin-top: 5em;
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 5em;
      }
    }
    ul + p {
      margin-top: 5em;
    }

    @include mq.mq($from: large) {
      padding-top: 8rem;
      padding-bottom: 12rem;
    }

    strong {
      display: block;
      @extend .font-size-h2;
      margin-top: 5.5rem;
      margin-bottom: 3rem;

      @include mq.mq($from: large) {
        margin-top: 11rem;
        margin-bottom: 6rem;
      }
    }
  }
}

.job-props {
  &__list {
    margin-top: 5rem;

    @include mq.mq($from: large) {
      margin-top: 1rem;
    }
  }

  &__title {
    font-size: 2rem;
    letter-spacing: 1.3px;
    line-height: 1.4;
    font-family: var(--headerFontFamily);
    text-transform: uppercase;
    margin-bottom: 0.3rem;

    @include mq.mq($from: large) {
      font-size: 2.5rem;
      letter-spacing: 1.5px;
    }
  }

  &__value {
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin-bottom: 3rem;

    @include mq.mq($from: large) {
      font-size: 2rem;
      margin-bottom: 6.5rem;
    }
  }
}
