@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	display: inline-flex;
}

.nav__toggle.action {
	position: absolute;
	top: 0.5rem;
	right: -1.5rem;
	width: 6rem;
	height: 6rem;
	transform-origin: center center;
	transition: transform var(--animationDuration) var(--animationEase);
	color: inherit;

	.icon {
		color: var(--color-white);
	}


	.nav__item--parent-opened & {
		transform: rotate(180deg);
	}

	@include mq.mq($from: large) {
		display: none;
		visibility: hidden;
	}
}

.nav__wrap {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;

	&--parent {
		.nav--main-nav &,
		.nav--meta-nav & {
			@include mq.mq($from: large) {
				justify-content: flex-end;
			}
		}
	}

	&--child {
		padding-top: 0;
		padding-bottom: 3rem;
		background-color: var(--color-primary-1);

		@include mq.mq($from: large) {
			position: absolute;
			left: 0;
			top: 116%;
			display: none;
			flex-direction: column;
			padding: 2.6rem;
			padding-top: 0;
			padding-bottom: 0;
			opacity: 0;
			transform: translate3d(-2.6rem, -0.6rem, 1px);
			white-space: nowrap;
			box-shadow: 0 2px 20px 0 #1A191920;
		}
	}

	&.nav__wrap--visible {
		display: flex;
		z-index: 9998;
		visibility: visible;
	}
}

.nav__item {
	position: relative;
	padding-left: 0;

	&:not(:first-child) {
		margin-top: 0;
	}

	.icon-chevron-down,
	.icon-chevron-down svg {
		@include mq.mq($from: large) {
			width: 20px;
			height: 12px;
		}
	}
}

.nav__item--child {
	padding-right: 7.5rem;
	width: 100%;

	&:hover {
		@include background-svg.params(svg.$svg-arrow-right, transparent, right, center, no-repeat, 22px, 22px, colors.$color-primary-1);
	}
}

.nav__link {
	text-decoration: none;
	color: var(--color-white);
	letter-spacing: -0.1px;

	&.is-active {
		color: var(--color-secondary-1);
	}

	@include mq.mq($from: large) {
		letter-spacing: -0.7px;
	}
}

.nav__link--child.nav__link--child {
	font-size: 1.6rem;
	line-height: 2.6rem;
	letter-spacing: 0.5px;
	font-weight: 400;
}
