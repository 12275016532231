@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);
	line-height: 1.5;

	background-color: var(--color-primary-1);
	hyphens: auto;
	font-variant: none;

	//background: white url('../../images/arrow-right.svg') 0 0 repeat;
	//background-size: 20px 20px;

	word-break: break-word;
	color: var(--bodyFontColor);
}

#main {
	position: relative;
	min-height: 100vh;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}