@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root{
    --job-list-grid: 1;
}

.section__title--jobs {
    font-size: 2.5rem;

    @include mq.mq($from: large) {
        font-size: 8rem;
    }
}
.job-list {
    display: grid;
    grid-template-columns: repeat(var(--job-list-grid), 1fr);
    gap: 2px;
    margin-bottom: 3rem;

    @include mq.mq($from: medium) {
        --job-list-grid: 2;
    }

    @include mq.mq($from: large) {
        --job-list-grid: 3;
        margin-bottom: 6rem;
    }

    &__item {
        background-color: var(--color-white);
        color: var(--color-black);
        display: flex;
        min-height: 213px;
        padding: 1.5rem;
        flex-direction: column;
        position: relative;

        @include mq.mq($from: large) {
            min-height: 376px;
            padding: 3rem;
        }
    }
}

.job {
    &__rate {
        font-size: 1.5rem;
        margin-bottom: 3rem;
        @include mq.mq($from: large) {
            font-size: 1.6rem;
            margin-bottom: 6rem;
        }
    }
    &__branch {
        font-size: 1.6rem;
        padding: 7px;
        background-color: var(--color-black);
        color: var(--color-white);
        text-transform: uppercase;
        display: inline-block;
        width: fit-content;
        margin-bottom: 1.5rem;
        @include mq.mq($from: large) {
            font-size: 1.8rem;
            padding: 9px;
            margin-bottom: 3rem;
        }
    }
    &__title {
        font-size: 2.3rem;
        margin-bottom: 4rem;
        @include mq.mq($from: large) {
            font-size: 3.5rem;
        }
    }
    &__place {
        font-size: 1.5rem;
        position: absolute;
        bottom: 15px;
        line-height: 1;
        @include mq.mq($from: large) {
            font-size: 1.6rem;
            bottom: 30px;
        }
    }
    &__link {
        color: var(--color-secondary-1);
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            content: "";
            @include background-svg.params(svg.$svg-arrow, transparent, right, bottom, no-repeat, 6.3rem, 6.3rem, colors.$color-secondary-1);

            @include mq.mq($from: large) {
                @include background-svg.params(svg.$svg-arrow, transparent, right, bottom, no-repeat, 10.5rem, 10.5rem, colors.$color-secondary-1);
            }
        }
/*
        .icon {
            width: 6.3rem;
            height: 6.3rem;

            @include mq.mq($from: large) {
                width: 10.5rem;
                height: 10.5rem;
            }
        }
        */
    }
}