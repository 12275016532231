@use "../_base/typography";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.form__pages {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 4rem;
}

.form__page {
  margin-right: 4rem;

  &--active {
    color: var(--color-primary-1);
  }
}

.form__page-number {
  @extend .font-weight-700;
  color: var(--color-primary-1);
}

.form {
  &__grid {
    display: grid;
    gap: 0 2rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  }

  &__col {
    grid-column-end: span 2;
    margin-bottom: 5rem;

    &--half {
      grid-column-end: span 1;
    }
  }
}

.form {
  &__field {
    margin-bottom: 2rem;

    label {
      display: none;

      &[for="salutation"],
      &[for="birthdate"],
      &[for="consent"] {
        display: block;
        margin-bottom: 1rem;
      }
    }

    input,
    select {
      background-color: var(--color-white);
      padding: 10px 8px;
      border: 1px solid var(--color-white);
      color: var(--color-black);
    }

    input {
      width: 100%;
      // @include mq.mq($until: large) {
      //   width: 100%;
      // }
    }

    &.field__checkbox {
      position: relative;
      padding-left: 6rem;

      label::before {
        content: "";
        height: 40px;
        left: 0;
        position: absolute;
        top: -6px;
        width: 40px;
        border: 2px solid var(--color-white);
      }

      label::after {
        content: "";
        @include background-svg.params(
          svg.$svg-check,
          transparent,
          center,
          center,
          no-repeat,
          20px,
          $fillColor: colors.$color-secondary-1
        );
        position: absolute;
        width: 40px;
        height: 40px;
        top: -4px;
        left: 2px;
        display: none;
      }

      input[type="checkbox"]:checked {
        & + label::after {
          display: block;
        }
      }
    }

    &--error {
      input {
        border: 1px solid var(--color-warning);
      }
    }
  }

  &__apply {
    display: grid;
    grid-template: "a" "c" "d" "e" "g" "h" "f" "i";
    grid-gap: 1rem 2rem;
    padding-bottom: 80px;

    @include mq.mq($from: large) {
      grid-template:
        "a a f"
        "c d f"
        "e g f"
        "h h f"
        "i . f";
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 120px;
    }

    .salutation {
      grid-area: a;
    }
    .firstname {
      grid-area: c;
    }
    .lastname {
      grid-area: d;
    }
    .mobile {
      grid-area: e;
    }
    .email {
      grid-area: g;
    }
    .consent {
      grid-area: h;
    }
    .apply {
      grid-area: i;
    }
    .form__upload {
      grid-area: f;
      padding: 2rem;

      @include mq.mq($from: large) {
        grid-row-start: 1;
        grid-row-end: 5;
      }
    }
  }
  &__job-alarm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem 2rem;
    padding-bottom: 80px;

    .form__field {
      margin-bottom: 0;
      input[type="email"] {
        width: 100%;
      }
      &.consent {
        grid-column: -1 / 1;
      }
    }

    @include mq.mq($from: large) {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 120px;
    }

    .action-primary {
      min-height: 0;
      padding: 1.4rem 1.5rem 1.2rem 1.9rem;
      &:after {
        width: 2rem;
        height: 2rem;
        background-size: 4rem;
      }
    }
  }
}

.form {
  &__upload {
    input[type="file"] {
      display: none;
    }

    &--desclaimer {
      margin-top: 2rem;
      font-size: 1.6rem;
    }
  }

  .errors {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    font-size: 1.6rem;
    color: var(--color-warning);

    li {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        content: unset;
      }
    }
  }
}

#documents {
  div {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}

.upload-error {
  color: var(--color-secondary-1);
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-secondary-1);

  &:empty {
    border: none;
  }
}

.message.error {
  color: var(--color-primary-1);
  background-color: var(--color-secondary-1);
  padding: 8px 15px;
}

#apply {
  overflow: hidden;
}
