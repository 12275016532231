@use "../mixins/mq";

$columns: 12;

$sizes: (
		'sm',
		'md',
		'lg'
);

.container {
	width: 100%;
	max-width: var(--container-width);
	margin-left: auto;
	margin-right: auto;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: var(--headerHeightSmall);

		@include mq.mq($from: large) {
			height: var(--headerHeight);
		}
	}

}

.row {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

.row--grid {
	display: grid;
	grid-template-columns: repeat($columns, 1fr);
	grid-column-gap: 10px;
	justify-content: center;
	grid-template-rows: auto;
	align-items: start;

	@include mq.mq($from: medium) {
		grid-template-columns: repeat($columns, 1fr);
		grid-column-gap: 2rem;
	}

	@include mq.mq($from: large) {
		grid-template-columns: repeat($columns, 1fr);
		grid-column-gap: 3.1rem;
	}
}

.row--reverse {
	@include mq.mq($from: medium) {
		direction: rtl;
	}

	* {
		direction: ltr;
		text-align: left;
	}
}

.col--half {
	width: 50%;
}

.wrapper {
	position: relative;
	width: 100%;
	padding-left: var(--spacing-wrapper);
	padding-right: var(--spacing-wrapper);

	.wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.offset-left {
	max-width: 119rem;
	margin-left: auto;

}

.middle {
	margin-left: auto;
	margin-right: auto;
}

.middle--medium {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 77rem;
	}
}

.middle--large {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 120rem;
	}
}

.middle--xl {
	
	@include mq.mq($from: small) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 120rem;
	}
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

	@if ($breakpointStart) {

		@include mq.mq($from: $breakpointStart) {

			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

			}
		}

	} @else {

		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}

		}
	}
}

@each $size in $sizes {

	@if $size == 'sm' {
		@include generateColumns($columns, $size);
	}

	@else if $size == 'md' {
		@include generateColumns($columns, $size, medium, large);
	}

	@else if $size == 'lg' {
		@include generateColumns($columns, $size, large);
	}
}

.offset-md-0 {
	@include mq.mq($from: medium) {
		grid-column-start: 1;
	}
}

.offset-lg-0 {
	@include mq.mq($from: large) {
		grid-column-start: 1;
	}
}
