@use "../mixins/mq";
@use "../utils/visually-hidden";

.header {
  position: sticky;
  z-index: 50;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--headerHeightSmall);
  background-color: var(--color-primary-1);

  box-shadow: 0 2px 20px 0 #1A191920;
  transition: transform var(--animationDuration) var(--animationEase);

  @include mq.mq($from: large) {
    height: var(--headerHeight);
  }
}

.header--scroll {
  transform: translateY(-120%);
}

.header__home-link {
  display: inline-flex;
  margin-left: 0;

  .icon {
    fill: var(--color-white);
  }

  > * {
    width: 163px;

    @include mq.mq($from: large) {
      width: 276px;
    }
  }
}

.header__home-link-text {
  @extend .visually-hidden;
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 4.2rem;
  padding-bottom: 5rem;
  z-index: 1;
  transform: translate3d(-100%, 60px, 0);
  background-color: var(--color-primary-1);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform var(--animationDuration) var(--animationEase);
/*
  @include mq.mq($from: medium, $until: large) {
    width: 37.5rem;
  }
*/
  @include mq.mq($from: large) {
    position: relative;
    height: auto;
    display: flex;
    justify-content: flex-end;
    padding: 4.9rem 0 0 0;
    transform: none;
    background: none;
    overflow: visible;
  }

  .header__nav--opened & {
    transform: translate3d(0, 60px, 0);

    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      transform: none;
    }
  }
}

.header__nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black-transparent);
  display: none;
  z-index: 0;

 /* .header__nav--opened & {
    @include mq.mq($from: medium, $until: large) {
      display: block;
    }
  }*/
}


.header__nav-meta {
  position: relative;

  @include mq.mq($from: large) {
    position: absolute;
    right: 0;
    top: -1.3rem;
  }
}

.header__hamburger {
  position: relative;
  margin-right: 0;
  z-index: 2;
  color: var(--color-white);

  @include mq.mq($from: large) {
    display: none;
    visibility: hidden;
  }
}
