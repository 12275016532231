@use "../mixins/mq";

.social {

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;

        @include mq.mq($from: medium) {
            grid-template-columns: 1fr 1fr;
            gap: 8rem 4rem;
        }
    }

    &__link {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 2rem;

        @include mq.mq($from: large) {
            gap: 3rem;
        }

        &:hover {
            color: var(--color-secondary-1);
        }
    }

    &__icon {
        width: 3rem;
        height: 3rem;

        @include mq.mq($from: large) {
            width: 5rem;
            height: 5rem;
        }
    }

    &__text {
        font-size: 2.5rem;

        @include mq.mq($from: large) {
            font-size: 5rem;
        }
    }
    
}