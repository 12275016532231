@use "../mixins/mq";
@use "../_base/typography";

.nav--main-nav {

	.nav__item--parent {
		position: relative;
		max-height: 5rem;
		width: 100%;
		margin: 0 2.2rem;
		transition: max-height 0.5s var(--animationEase);
		overflow: hidden;
		border-bottom: 1px solid var(--color-line);

		&::before {
			content: unset;
		}

		@include mq.mq($from: large) {
			width: auto;
			margin-left: 8.4rem;
			margin-right: 0;
			overflow: visible;
			border-bottom: 0;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.nav__item--child {
		height: 5.9rem;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--color-white);

		&::before {
			content: unset;
		}

		@include mq.mq($from: large) {
			margin-bottom: 0;
			height: 58px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid var(--color-white);

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.nav__item--parent-opened {
		max-height: 800px;

		@include mq.mq($from: large) {
			max-height: unset;
		}
	}

	.nav__link {
		@extend .font-weight-700;
	}

	.nav__link--child {
		display: block;

		&:hover {
			color: var(--color-secondary-1);
		}
	}

	.nav__link--parent {
		display: flex;
		height: 7rem;
		align-items: center;
		font-size: 1.8rem;
		line-height: 2.6rem;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.3px;

		.not-touch & {
			&:hover {
				color: var(--color-secondary-1);
			}
		}

		.header__nav--opened & {
			font-size: 1.6rem;
			line-height: 1.375;
			font-weight: 400;
			letter-spacing: 0.7px;
		}
	}
}
