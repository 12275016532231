@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root {
	--slideWidthDesktop: 113.2rem;
	--slideWidthWide: 142.6rem;
};

.gallery__title.gallery__title {
	margin: 0 auto 4rem;

	@include mq.mq($from: large) {
		max-width: var(--slideWidthDesktop);
	}

	@include mq.mq($from: wide) {
		max-width: var(--slideWidthWide);
	}
}

.gallery__background {
	padding-top: 8rem;
	background-color: var(--lemon);
	transition: min-height 0.3s ease;

	@include mq.mq($from: medium) {
		padding-top: 0;
	}

}

.gallery__wrapper {
	position: relative;

	.gallery__button {
		width: 33px;
		padding: 0;
		color: var(--blue);
		transition: transform var(--animationDuration) var(--animationEase);

		.not-touch & {
			&:hover {
				transform: scale(2);
			}
		}
	}

	.gallery__button-prev {
		display: none;
		visibility: hidden;
		left: 50%;
		margin-left: calc(0px - ((#{var(--slideWidthDesktop)} + 3.3rem) / 2));
		@include background-svg.params(svg.$svg-arrow-left, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);

		&:after {
			display: none;
			visibility: hidden;
		}

		@include mq.mq($from: medium) {
			display: flex;
			visibility: visible;
		}

		@include mq.mq($from: wide) {
			margin-left: calc(0px - ((#{var(--slideWidthWide)} + 3.3rem) / 2));
		}
	}

	.gallery__button-next {
		left: 322px;
		right: auto;
		margin-left: calc(3.3rem / -2);
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);


		&:after {
			display: none;
			visibility: hidden;
		}

		@include mq.mq($from: medium) {
			left: auto;
			right: 50%;
			margin-left: 0;
			margin-right: calc(0px - ((#{var(--slideWidthDesktop)} + 3.3rem) / 2));
		}

		@include mq.mq($from: wide) {
			margin-right: calc(0px - ((#{var(--slideWidthWide)} + 3.3rem) / 2));
		}

	}
}


.gallery__slider .gallery__slide {
	margin: 0;
	padding-left: 0;
	
	width: 322px;
	max-width: 322px;

	&::before {
		content: unset;
	}

	@include mq.mq($from: medium) {
		width: 100%;
		max-width: 113.2rem;
	}

	@include mq.mq($from: wide) {
		max-width: 142.6rem;
	}
}

.gallery__bottom {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	padding: var(--spacingMedium) 0 var(--spacingBig);

	@include mq.mq($from: medium) {
		max-width: 113.2rem;
		padding-bottom: var(--spacingWide);;
	}

	@include mq.mq($from: wide) {
		max-width: 142.6rem;
	}
}

.gallery__pagination {
	width: 100%;
	margin-bottom: var(--spacingSmall);

	@include mq.mq($from: medium) {
		width: 180px;
	}
}

.gallery__pagination-fraction {
	display: flex;
	font-size: 2.8rem;
	line-height: 5rem;
}

.gallery__pagination-current {
	font-size: 4rem;
	line-height: 6rem;
}

.gallery__pagination-total {
	transform: translateY(-5px);
}


.gallery__slider .gallery__slide {
	margin: 0;
	padding-left: 0;

	&::before {
		content: unset;
	}
}

.splide__arrows.splide__arrows--ltr {
	position: absolute;
	z-index: 10;
	bottom: 0;
	right: 5.3rem;
	width: 4rem;

	@include mq.mq($from: xsmall) {
		left: 28.2rem;
		right: 0;
	}

	@include mq.mq($from: small) {
		left: 26.9%;
		right: 0;
	}

	@include mq.mq($from: large) {
		width: 8rem;
	}

}

.splide__arrow {
	width: 4rem;
	height: 4rem;
	color: var(--color-primary-1);
	background-color: var(--color-secondary-1);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .3s ease-in-out;

	.not-touch & {
		&:hover {
			background-color: var(--color-white);
		}
	}

	&:disabled, &:disabled:hover  {
		background-color: var(--color-grey);
		color: var(--color-white);
		cursor: auto;
	}

	@include mq.mq($from: large) {
		width: 8rem;
		height: 8rem;
	}

	.icon {
		width: 50%;
		height: 50%;
	}

}

.slide__description {
	font-size: 1.6rem;
	width: 100%;
	padding: 2rem;

	@include mq.mq($from: medium) {
		width: 46.2%;
		margin: 0 auto;
		padding: 2rem 0;
		
	}
}
