@use "../mixins/mq";

.nav--footer-nav {
	.nav__wrap {
		flex-direction: column;

		@include mq.mq($from: small) {
			flex-direction: row;
			margin-left: 3rem;
		}
	}

	.nav__item {
		margin-top: 2rem;

		&::before {
			content: unset;
		}

		@include mq.mq($from: small) {
			margin-top: 0;
			margin-right: 3.6rem;
		}
	}

	.nav__link {
		color: var(--white);
		letter-spacing: 0.5px;

		.not-touch & {
			&:hover {
				color: var(--color-primary-2);
			}
		}

		&.is-active {
			color: var(--color-primary-2);
		}
	}
}
