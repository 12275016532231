$fontPath: '../../webfonts/';

@font-face {
	font-family: 'Inter';
	src: url('#{$fontPath}font-bold/Inter-Bold.ttf');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('#{$fontPath}font-light/Inter-Light.ttf');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('#{$fontPath}font-regular/Inter-Regular.ttf');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sequel100Black-75';
	src: url('#{$fontPath}sequel/Sequel100Black-75.ttf');
	font-style: normal;
	font-display: swap;
}