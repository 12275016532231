@use "../mixins/mq";

.nav--social-nav {
	margin-top: -3px;
	margin-bottom: 5.6rem;
	order: 1;


	@include mq.mq($from: small) {
		margin-bottom: 0;
		order: 2;
	}

	.nav__item::before {
		content: unset;
	}

	.nav__item {
		margin-top: 0;
	}

	.nav__item:not(:first-child) {
		margin-left: 3.2rem;

		@include mq.mq($from: large) {
			margin-left: 2.1rem;
		}
	}

	.nav__link {
		color: var(--color-secondary-1);
	}


	.icon svg {
		width: 26px;
		height: 26px;
	}

	.not-touch & {
		.nav__link:hover {
			color: var(--color-white);
		}
	}
}
