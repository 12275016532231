@use "../mixins/mq";
@use "../_base/typography";

.nav--meta-nav {
	margin-top: 8.8rem;
	@extend .font-size-h5;
	padding-bottom: 6rem;

	@include mq.mq($from: large) {
		margin-top: 0;
		padding-bottom: 0;
	}

	.nav__item--parent {
		width: 100%;
		margin: 1.7rem 2.2rem;

		&::before {
			content: unset;
		}

		@include mq.mq($from: large) {
			width: auto;
			margin-left: 5.2rem;
			margin-right: 0;
		}
	}

	.nav__link {
		.not-touch & {
			&:hover {
				color: var(--color-secondary-1);
			}
		}
	}
}
