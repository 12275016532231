@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

.action {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);
	cursor: pointer;
}

.action-primary {
	min-height: var(--button-primary-min-height);
	padding: 1.5rem 1.5rem 1.2rem 1.9rem;
	font-size: var(--font-size-button);
	font-weight: bold;
	color: var(--color-primary-1);
	background-color: var(--color-secondary-1);
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	letter-spacing: 1px;
	line-height: 1.4;

	&::after {
		content: '';
		width: 4rem;
		height: 4rem;
		display: block;
		margin-left: 2.5rem;
		@include background-svg.params(svg.$svg-arrow, transparent, center, center, no-repeat, 6rem, 6rem, colors.$color-primary-1);

		@include mq.mq($from: large) {
			width: 4rem;
			height: 4rem;
			margin-left: 3.1rem;
			@include background-svg.params(svg.$svg-arrow, transparent, center, center, no-repeat, 8rem, 8rem, colors.$color-primary-1);
		}
	}

	@include mq.mq($from: large) {
		padding: 1.8rem 2.5rem;
		line-height: 1;
	}

	&.disabled {
		background-color: var(--color-grey);
	}

	.not-touch & {
		&:hover {
			color: var(--color-primary-1);
			background-color: var(--color-white);
		}
	}

}

.action-primary--inverted {
	@extend .action-primary;
	color: var(--color-primary-1);

	border-color: var(--color-white);
	background-color: var(--color-white);

	&.disabled {
		color: var(--color-white);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background: none;
		}
	}
}

.action-primary--cta {
	font-size: 1.6rem;
	letter-spacing: 0.5px;
	line-height: 1.5;
	align-items: flex-end;
	box-shadow: 5px 5px 30px 0 rgba(0,0,0,0.5);
	border: 3px solid var(--color-primary-1);
	border-right: none;
	padding: 0.9rem 1.4rem;
	min-height: unset;

	&::after {
		content: unset;
	}
}

.action-apply {
	@extend .action-primary--inverted;
	white-space: nowrap;
	border: 1px solid var(--color-white);

	&::after {
		content: unset;
	}

	@include mq.mq($until: medium) {
		font-size: 1.3rem;
	}

}


.action-secondary {
	font-size: var(--font-size-button);
	@extend .font-weight-700;
	color: var(--color-secondary-1);
	text-decoration: underline;
	text-transform: uppercase;
	text-underline-offset: 5px;

	&.disabled {
		color: var(--color-grey);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
		}
	}

	.font-color-white & {
		@extend .action-secondary--inverted;
	}
}

.action-secondary--inverted {
	@extend .action-secondary;
	color: var(--color-white);

	border-color: var(--color-white);
	background: none;

	&.disabled {
		color: var(--color-grey);
	}

	.not-touch & {
		&:hover {
			color: var(--color-primary-1);
			background-color: var(--color-white);
			border-color: var(--color-white);
		}
	}
}

.action-fab {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
	border: 0.1rem solid var(--color-primary-1);

	.not-touch & {
		&:hover {
			@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		}
	}

	.not-touch .c-three_columns-item:hover & {
		@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
	}

	&--inverted {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		border-color: var(--color-white);

		.not-touch & {
			&:hover {
				border-color: var(--color-primary-1);
			}
		}
	}

	&.disabled {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-grey);
		border-color: var(--color-grey);
	}
}

.action-download {
	@extend .action-primary;

	&::after {
		margin-left: 1.7rem;
		@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 3.4rem, 3.4rem, colors.$color-primary-1);

		@include mq.mq($from: large) {
			margin-left: 3rem;
			@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 4rem, 4rem, colors.$color-primary-1);
		}
	}
	
}

.action-footer {
	color: var(--color-white);

	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}
