@use "../_config/colors";
@use "../mixins/mq";
@use "vars";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    display: block;
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    font-weight: var(--font-weight-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    //margin-top: calc(var(--spacing-#{$size}) * 2);
    margin-bottom: var(--spacing-#{$size});
    width: 100%;
    box-sizing: border-box;

    .no-margin & {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}

h1, h2, .h1, .h2, .font-size-h1, .font-size-h2 {
  font-family: var(--headerFontFamily, vars.$headerFontFamily);
  text-transform: uppercase;
}

.textImage {
  h2 {

    @include mq.mq($from: large){
      font-size: clamp(3.9rem, 3.2vw, 5.5rem);
    }
  }
  h4, .h4 {
    @include mq.mq($from: large) {
      font-size: clamp(1.7rem, 1.5vw, 2.5rem);
    }
  }
}


h4, .h4, .font-size-h4 {
  text-transform: uppercase;
  font-weight: bold;
}

strong,
b,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}

blockquote {
  margin-top: 5rem;
  margin-bottom: 7.3rem;
  quotes: "«" "»" "«" "»";
  color: var(--color-secondary-1);
  font-size: 2.3rem;
  font-weight: 300;
  letter-spacing: 0.23px;
  line-height: 3rem;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include mq.mq($from: large) {
    margin-top: 14.3rem;
    margin-bottom: 11rem;
    font-size: 3.5rem;
    letter-spacing: 0.5px;
    line-height: 4.5rem;
  }
}

figcaption {
  padding-top: 0;
  text-align: left !important;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 2.5rem;

  @include mq.mq($from: large) {
    padding-top: 0.7rem;
    letter-spacing: 1px;
    line-height: 2.6rem;
  }
}

.text {
  figure {
    img {
      aspect-ratio: 1.77;
    }
  }
}

::marker {
  color: var(--color-secondary-1);
  @extend .font-weight-700;
}

ul, ol {
  margin-left: 1em;
  margin-top: 4.8rem;

  @include mq.mq($from: medium) {
    margin-top: 7.1rem;
  }
}

li {
  padding-left: 1.2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
  line-height: 28px;

  @include mq.mq($from: large) {
    padding-left: 0.3rem;
    margin-top: 1.3rem;
    margin-bottom: 1.8rem;
  }

  &:not(:first-child) {
    margin-top: 0.8rem;

  }
}

ul {
  li {
    list-style-type: none;
    position: relative;

    &::before {
      content: '';
      width: 11px;
      height: 3px;
      position: absolute;
      top: 11px;
      left: -18px;
      background-color: var(--color-secondary-1);
    }
  }

  &.checklist {
    li {
      margin-bottom: 2.2rem;
      padding-left: 1.2rem;

      &::before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFE633' cx='20' cy='20' r='20'/%3E%3Cpath d='m10.546 20.564 7.533 6.885 12.417-14.513' stroke='%231E5FE6' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E");
        background-color: unset;
        width: 2rem;
        height: 2rem;
        top: 2px;
      }
    }
  }
}

ol {
  counter-reset: item;
  list-style-type: none;
  li {
    position: relative;

    @include mq.mq($from: large) {
      padding-left: 1.7rem;
      margin-bottom: 2.1rem;
    }

    &::before {
      content: counter(item, decimal-leading-zero); 
      counter-increment: item;
      position: absolute;
      top: 2px;
      left: -18px;
      color: var(--color-secondary-1);
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 22px;

      @include mq.mq($from: large) {
        top: 5px;
      }
    }
  }
}

p {

  margin-bottom: var(--paragraph-spacing-bottom);
  line-height: 28px;
  letter-spacing: 0.55px;

  @include mq.mq($from: large) {
    line-height: 30px;
  }

}

a {
  color: var(--color-white);

  &:hover {
    text-decoration: none;
  }
}

.lead {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 2.2rem;
  margin-bottom: 0.8rem;

  @include mq.mq($from: large) {
    font-size: 2.5rem;
    letter-spacing: 1.5px;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.text {
  h2, h3 {
    + p {
      margin-bottom: var(--spacing-h3);
    }
  }
}