@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";

.footer {
  color: var(--color-white);

  .action-secondary {
    @extend .action-secondary--inverted;
  }
}

.footer__top {
  padding-top: 2.3rem;
  padding-bottom: 4rem;
  background-color: var(--color-black);

  @include mq.mq($from: large) {
    padding-bottom: 12rem;
  }
}

.footer__top-left {
  padding-bottom: 4.8rem;

  h3 {
    letter-spacing: -0.8px;

    @include mq.mq($from: large) {
      letter-spacing: 0.3px;
    }
  }

  p {
    margin-top: 3.1rem;

    @include mq.mq($from: large) {
      margin-top: 5.4rem;
    }
  }

  @include mq.mq($from: large) {
    padding-bottom: 0;
  }
}

.footer__top-right {
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  line-height: 2.2;
  letter-spacing: 0.5px;
  justify-content: space-between;

  @include mq.mq($from: large) {
    margin-top: 3.5rem;
    font-size: 2rem;
    justify-content:unset;
  }
}

.footer__address {
  margin-bottom: 4.2rem;

  @include mq.mq($from: large) {
    margin-bottom: 3rem;
  }
}

.footer__address,
.footer__contact {
  @include mq.mq($from: large) {
    //width: calc(100% * 3 / 6 - #{6rem});
    //@include col.params(3, 6, 6rem);
  }
}

.footer__contact {
  margin-left: 0;
  font-size: 1.8rem;
  line-height: 1.6;

  
  @include mq.mq($from: small) {
    font-size: 1.6rem;
  }

  @include mq.mq($from: large) {
    margin-left: 9.9rem;
    margin-top: 0.8rem;
  }

  .action-footer {
    color: var(--color-secondary-1);
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-underline-offset: 5px;

    &:first-child {
      margin-bottom: 2.4rem;
    }

    @include mq.mq($from: large) {
      letter-spacing: 2.4px;
    }

    .not-touch & {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.footer__bottom {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: var(--font-size-h5);
  background-color: var(--color-black);
  letter-spacing: 0.5px;

  @include mq.mq($from: small) {
    font-size: var(--font-size-h6);
  }

  .row {
    @include mq.mq($from: small) {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.footer__copyright {
  text-transform: capitalize;
  line-height: 28px;
}

.footer__bottom-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  order: 2;

  @include mq.mq($from: small) {
    order: 1;
    width: auto;
    flex-direction: row;
    margin-bottom: 0;
  }
}
