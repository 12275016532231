@use "../mixins/mq";

.fullscreen {

	.section {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 50rem;
		height: calc(100vh - var(--headerHeight));
		height: calc(100svh - var(--headerHeight));
		max-height: 76rem;

		@include mq.mq($from: large) {
			max-height: 93rem;
		}
	}

	.figure {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&-text {

		h1, .h1, .font-size-h1 {
			margin-bottom: calc( var(--font-size-h1)*(1 - var(--line-height-h1)) );
			letter-spacing: 3.5px;
			
			@include mq.mq($until: small) {
				font-size: 1.8rem;
			}

			@include mq.mq($from: large) {
				margin-bottom: calc( var(--font-size-h1)*(1 - var(--line-height-h1))*2 );
			}
		}
		
		p {
			width: 100% ;
			margin-top: 2.5rem;

			@include mq.mq($from: large) {
				margin-top: 5.5rem;
				//width: calc( var(--container-width) * 4 / 12 );
			}
		}
	}
}
