@use "../mixins/mq";

.icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	font-style: normal;
	background-size: 100% 100%;

	svg {
		width: 100%;
		height: auto;
	}
}

$icons: (
		('chevron-down', 22, 22, 22, 22, 22, 22),
		('chevron-right', 6, 11, 6, 11, 6, 11),
		('home', 30, 27, 30, 27, 31, 36),
		('play', 20, 20, 20, 20, 36, 40),
);


@each $name, $widthSmall, $heightSmall, $widthMedium, $heightMedium, $widthLarge, $heightLarge in $icons {
	.icon-#{$name},
	.icon-#{$name} svg {
		width: #{$heightSmall}px;
		height: #{$heightSmall}px;

		@include mq.mq($from: medium) {
			width: #{$widthMedium}px;
			height: #{$heightMedium}px;
		}

		@include mq.mq($from: large) {
			width: #{$widthLarge}px;
			height: #{$heightLarge}px;
		}
	}
}
