@use "../mixins/mq";
@use "../_base/typography";

@mixin item-label {
  display: flex;
  align-items: center;
  gap: 1.6rem 2rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
}


.teaser-services__section {
  @include mq.mq($from: medium) {
    --gap-image: 3rem;
    --width-image: 36.9411764706%;
  }

  @include mq.mq($from: large) {
    overflow: visible;
  }
}

.teaser-services__content {
  @include mq.mq($from: large) {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 0 3rem;
  }
}

.teaser-services__list {
  @include mq.mq($from: medium) {
    margin: 0 calc(var(--spacing-wrapper) * -1) 0 0;
  }

  @include mq.mq($from: large) {
    width: 100%;
  }
}

.teaser-services__item {
  padding: 2.4rem 0;
  border-bottom: 1px solid #FFFFFF;

  @include mq.mq($from: medium) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    gap: 0 var(--gap-image);
    padding: 0;
    border: none;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - var(--width-image) - var(--gap-image));
      height: 100%;
      background-color: transparent;
      border-bottom: 1px solid #FFFFFF;
    }
  }

  @include mq.mq($from: large) {
    display: block;

    &::before {
      width: 100%;
    }
  }

  &:first-child {
    border-top: 1px solid #FFFFFF;

    @include mq.mq($from: medium) {
      border-top: none;

      &::before {
        border-top: 1px solid #FFFFFF;
      }
    }
  }
}

.teaser-services__figure {
  width: 21.6rem;
  margin: 0 0 2.4rem;
  font-size: 0;

  @include mq.mq($from: medium) {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: var(--width-image);
    align-self: flex-start;
    margin-bottom: .8rem;
  }

  @include mq.mq($from: large) {
    position: sticky;
    top: var(--headerHeight);
    width: 100%;
    opacity: 0;
    height: 0;

    &.is-hovered {
      opacity: 1;
      height: auto;
    }

    .teaser-services__item > & {
      display: none;
    }
  }
}

.teaser-services__link {
  @include item-label;
  text-decoration: none;
  color: var(--color-white);

  @include mq.mq($from: medium) {
    padding: 6.4rem 0;
  }

  @include mq.mq($from: large) {
    padding: 10rem 0;
    transition: transform 320ms ease;
  }

  &:hover {
    @include mq.mq($from: large) {
      transform: translateX(6rem);
    }
  }
}

.teaser-services__details {
  @include mq.mq($from: medium) {
    padding: 6.4rem 0;
  }

  @include mq.mq($from: large) {
    padding: 0;
  }

  &[open] {
    @include mq.mq($from: large) {
      padding-bottom: 10rem;
    }
  }
}

.teaser-services__summary {
  @include item-label;

  @include mq.mq($from: large) {
    padding: 10rem 0;
    transition: transform 320ms ease;
  }

  .teaser-services__details[open] & {
    @include mq.mq($from: large) {
      padding-bottom: 0;
    }
  }

  &::-webkit-details-marker {
    display: none
  }

  &:hover {
    @include mq.mq($from: large) {
      transform: translateX(6rem);

      .teaser-services__details[open] & {
        transform: translateX(0);
      }
    }
  }
}

.teaser-services__label {
  @extend .font-size-h3;
  margin: 0;
  font-weight: 300;
}

.teaser-services__icon {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-secondary-1);

  @include mq.mq($from: large) {
    width: 4rem;
    height: 4rem;
    opacity: 0;
    transition: opacity 160ms linear;
  }

  path:first-child {
    .teaser-services__details[open] & {
      opacity: 0;
    }
  }

  .teaser-services__summary:hover &, .teaser-services__link:hover & {
    @include mq.mq($from: large) {
      opacity: 1;
    }
  }
}

.teaser-services__richtext {
  padding-top: 0.8rem;
}

.teaser-services__images {
  display: none;

  @include mq.mq($from: large) {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    display: block;
    margin: 0 calc(var(--spacing-wrapper) * -1) 0 0;
  }
}

