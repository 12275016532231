@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;
}

.section--spacing {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@include mq.mq($from: large) {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}

	&-fullscreen {
		padding-top: 4rem;
		padding-bottom: 6rem;

		@include mq.mq($from: large) {
			padding-top: 12rem;
			padding-bottom: 6rem;
		}	
	}
}


.section--spacing-top {
	padding-top: 4rem;

	@include mq.mq($from: large) {
		padding-top: 12rem;
	}
}

.section--spacing-detail {
	padding-top: 4rem;

	@include mq.mq($from: large) {
		padding-top: 8rem;
	}
}

.section__title {
	margin-bottom: 4rem;
}

.section__title--half {
	text-wrap: balance;
	margin-bottom: 3rem;
	@include mq.mq($from: large) {
		max-width: 88rem;
		margin-bottom: 10rem;

		.accordion--small & {
			margin-bottom: 6rem;
		}
	}
}
