@use "../mixins/mq";
@use "../mixins/col";

:root {
    --grid-gap: 10px;
    --padding-card-text: calc( (100% - 11 * var(--grid-gap))/12 );

    @include mq.mq($from: small) {
        --grid-gap: 20px;
    }
    @include mq.mq($from: large) {
        --grid-gap: 31px;
    }
}

.card {

    @include mq.mq($from: large) {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: calc((100% - 3.1rem) * 9 / 84);
        max-width: 192rem;
    }

    @include mq.mq($from: large, $until: cwide) {
        height: calc(56.4rem + (90 - 56.4) * ((100vw - 120rem) / (192 - 120)));
    }
    @include mq.mq($from: cwide) {
        height: 90rem;
    }

    &__image {
        min-height: 187px;

        

        @include mq.mq($from: xsmall, $until: small) {
			min-height: calc(187px + (405 - 187) * ((100vw - 375px) / (660 - 375)));
		}
		
        @include mq.mq($from: small) {
			min-height: 405px;
		}
        @include mq.mq($from: medium, $until: large) {
			min-height: calc(405px + (717 - 405) * ((100vw - 768px) / (1200 - 768)));
		}
        @include mq.mq($from: large) {
			min-height: 717px;
		}

        .figure {
            @include mq.mq($from: large) {
                @include col.params(9, 12, 3.1rem);
                max-width: 140rem;;
                margin-right: 0;
            }
            @include mq.mq($from: cwide) {
                margin-right: calc((100vw - 192rem)/2);
                width: 140rem;
                height: 90rem;
            }
        }
    }

    &__text {
        position: relative;
        z-index: 1;
        background-color: var(--color-primary-1);
        padding-top: 3rem;
        padding-left: var(--padding-card-text);
        padding-right: var(--padding-card-text);
        
        @include mq.mq($from: large) {
            @include col.params(6, 12, 3.1rem);
            padding-left: 0;
            padding-right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                width: 55%;

                &.h2, &.h3 {
                    width: 99%;
                }
            }
        }

        @include mq.mq($from: large, $until: xlarge) {
            h2, .h2, .font-size-h2 {
                font-size: 3.5rem;
            }
        }

        @include mq.mq($from: large, $until: cwide) {
            min-height: calc(36.4rem + (66 - 36.4) * ((100vw - 120rem) / (192 - 120)));
        }
        @include mq.mq($from: cwide) {
            height: 66rem;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .action {
            margin-bottom: 2.5rem;
        }

        @include mq.mq($from: large) {
            flex-direction: row;
            align-items: center;
            min-height: 9rem;

            .action {
                margin-bottom: 0;

                &--tel, &--email {
                    margin-right: 4rem;

                    @include mq.mq($from: xlarge) {
                        margin-right: 6rem;
                    }
                }

                &:nth-child(2) {
                    margin-right: 0;
                }

                &--entry {
                    margin-left: auto;
                }
            }
        }
    }

}