@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";


img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

video {
	width: 100%;
}

.figure {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
	}

	&-three {
		height: 0;
		padding-bottom: 76%;
	}

	&-two {
		height: 0;
		padding-bottom: 74.85%;
		//aspect-ratio: 1.524;
		width: 100vw;
		transform: translateX(-23px);

		@include mq.mq($from: small) {
			transform: translateX(0);
			width: 100%;
		}

		@include mq.mq($from: large) {
			padding-bottom: 69.4265%;
			aspect-ratio: 1.5278;
			max-width: 1100px;
			width: auto;
			transform: translateX(-40px);
		}

		@include mq.mq($from: xxlarge, $until: cwide) {
			transform: translateX( calc(-40px - 20 * ((100vw - 1880px) /  40)) );
		}

		@include mq.mq($from: cwide) {
			transform: translateX(-60px);
		}

		.row--reverse & {
			transform: translateX(-23px);

			@include mq.mq($from: small) {
				transform: translateX(0);
				width: 100%;
			}
			@include mq.mq($from: large) {
				transform: translateX(40px);
			}
	
			@include mq.mq($from: xxlarge, $until: cwide) {
				transform: translateX( calc(40px + 20 * ((100vw - 1880px) /  40)) );
			}
	
			@include mq.mq($from: cwide) {
				transform: translateX(60px);
			}
		}
	}

	&-fullscreen:after {
		background: rgb(0,0,0);
		background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.51) 100%);
	}
	&-cta:after {
		content: unset;
	}
}

.figure-slider {
	height: 0;
	padding-bottom: 56.27%;
}

.legal figure {
	margin-top: 3rem;
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}
