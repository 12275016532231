@use "../_config/colors";
@use "../mixins/mq";

:root {
  --container-width: 180rem;

  --globalFontSize: 1.8rem;
  --globalLineHeight: 1.56;

  --color-black: #{colors.$color-black};
  --color-grey:  #{colors.$color-grey};
  --color-white:  #{colors.$color-white};
  --color-primary-1:  #{colors.$color-primary-1};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};
  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};
  --color-line:  #{colors.$color-line};
  --paragraph-spacing-top: 2rem;
  --paragraph-spacing-bottom: 1.5rem;

  --font-size-h1: 3rem;
  --font-size-h2: 2.5rem;
  --font-size-h3: 2.3rem;
  --font-size-h4: 2rem;
  --font-size-h5: 1.6rem;
  --font-size-h6: 1.5rem;

  --line-height-h1: 1.33;
  --line-height-h2: 1.4;
  --line-height-h3: 1.3;
  --line-height-h4: 1.5;
  --line-height-h5: 1.375;
  --line-height-h6: 1.67;

  --font-weight-h1: normal;
  --font-weight-h2: normal;
  --font-weight-h3: 300;
  --font-weight-h4: 700;
  --font-weight-h5: 700;
  --font-weight-h6: 400;

  --letter-spacing-h1: 0.4px;
  --letter-spacing-h2: 1px;
  --letter-spacing-h3: 0.1px;
  --letter-spacing-h4: 1px;
  --letter-spacing-h5: 1px;
  --letter-spacing-h6: 0.5px;

  --spacing-h1: 1.2rem;
  --spacing-h2: 1.1rem;
  --spacing-h3: 3.8rem;
  --spacing-h4: 0.6rem;
  --spacing-h5: 1em;
  --spacing-h6: 1em;



  --spacing-wrapper: 2.25rem;

  --font-size-button: 1.6rem;

  --button-primary-min-height: 7.5rem;
  --button-primary-min-width: 20rem;

  --bodyFontFamily: "Inter", Helvetica, Arial, sans-serif;
  --headerFontFamily: "Sequel100Black-75", Helvetica, Arial, sans-serif;
  --bodyFontColor: var(--color-white);

  --headerHeightSmall: 9rem;
  --headerHeight: 9rem;

  --hamburgerLineHeight: 3px;
  --hamburgerWidth: 32px;
  --hamburgerSpacing: 12px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);

  @include mq.mq($from: medium) {
    --spacing-wrapper: 4rem;
  }

  @include mq.mq($from: large) {
    --globalFontSize: 2rem;
    --paragraph-spacing-top: 3.4rem;
    --paragraph-spacing-bottom: 1.4rem;

    --font-size-h1: 8rem;
    --font-size-h2: 5.5rem;
    --font-size-h3: 3.5rem;
    --font-size-h4: 2.5rem;
    --font-size-h5: 1.8rem;
    --font-size-h6: 1.6rem;

    --line-height-h1: 1.125;
    --line-height-h2: 1.18;
    --line-height-h3: 1.29;
    --line-height-h4: 1.4;
    --line-height-h5: 1.44;
    --line-height-h6: 1.625;

    --letter-spacing-h1: 3.5px;
    --letter-spacing-h2: 1px;
    --letter-spacing-h3: 0.5px;
    --letter-spacing-h4: 1.5px;
    --letter-spacing-h5: 1px;
    --letter-spacing-h6: 0.5px;

    --spacing-h1: 8.7rem;
    --spacing-h2: 7rem;
    --spacing-h3: 3.8rem;
    --spacing-h4: 0.6rem;
    --spacing-h5: 1.2em;
    --spacing-h6: 1.1em;

    --font-size-button: 1.8rem;

    --button-primary-min-height: 9rem;

    --headerHeight: 15rem;
  }
}

$bodyFontFamily: "Inter", Helvetica, Arial, sans-serif;

$headerFontFamily: "Sequel100Black-75", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animationDuration: 0;
    --animationEase: none;
  }
}
