@use "../mixins/mq";

.textImage__item {
	padding-bottom: 6rem;
	
	@include mq.mq($from: large) {
		padding-bottom: 0;
	}

	&:not(:first-child) {
		margin-top: 1px;
		margin-bottom: 1px;

		@include mq.mq($from: large) {
			margin-top: 1px;
			margin-bottom: 1px;
		}
	}

	&::before {
		content: '';
		height: 1px;
		width: 100vw;
		background-color: var(--color-white);
		display: block;
		position: absolute;
		top: -1px;
		left: -2.25rem;

		@include mq.mq($from: medium) {
			left: -4rem;
			right: -4rem;
		}
		@include mq.mq($from: xxlarge) {
			left: -6rem;
			right: -6rem;
			width: calc(100% + 12rem);
		}
	}
	&::after {
		content: '';
		height: 1px;
		width: 100vw;
		background-color: var(--color-white);
		display: block;
		position: absolute;
		bottom: -1px;
		left: -2.25rem;

		@include mq.mq($from: medium) {
			left: -4rem;
		}
		@include mq.mq($from: xxlarge) {
			left: -6rem;
			right: -6rem;
			width: calc(100% + 12rem);
		}
	}

	&:last-child {
		&::after {
			bottom: 0px;
		}
	}
}

.textImage__item-text {
	padding-top: 3rem;
	

	@include mq.mq($from: large) {
		padding-top: 3rem;
		padding-bottom: 3rem;
		margin: auto 0 auto 3rem;

		.row--reverse & {
			padding-left: 0;
			margin: auto 0;
		}
	}

	h3, .h3 {
		margin-bottom: 0.7rem;

		@include mq.mq($from: large) {
			margin-bottom: 2rem;
		}
	}

	p {
		&:not(.lead) {
			margin-bottom: 2.3rem;
	
			@include mq.mq($from: large) {
				margin-bottom: 3.5rem;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	
}

.textImage__item-image {

	@include mq.mq($from: large) {
		height: 100%;
	}

	.figure-two {
		@include mq.mq($from: large) {
			height: inherit;
			aspect-ratio: unset;
			
		}
	}
}