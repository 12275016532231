@use "../mixins/mq";
@use "../atoms/grid";

.cta {

	.container:before,
	.figure {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		aspect-ratio: 375 / 241;
		@extend .container;

		picture {
			height: inherit;
		}

		@include mq.mq($from: small) {
			aspect-ratio: 768 / 493;
		}
		@include mq.mq($from: large) {
			aspect-ratio: 14 / 9;
		}
	}

	

	.icon {
		width: 4rem;
		margin-bottom: 2rem;
	}
}

.cta_button {
	position: fixed;
	right: 0;
	bottom: 4rem;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: .3s ease-in-out;

	&--show {
		opacity: 1;
		visibility: visible;
	}
}